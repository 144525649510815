<template>
    <div>
        <div class="m-wrapper04">
            <!-- <div class="m-header01">
                <span class="detail-lab02">出诊地点</span>
                <a @click="watchDoctorDetail" class="link-btn01 f-fr">医生主页</a>
            </div> -->
            <div class="doctor">
                <div class="avadar">
                    <img src="@/assets/images/avatar-2.png" alt="">
                </div>
                <div class="info">
                    <div class="info-up">
                        <span class="name">{{doctorInfo.doctorName}}</span>
                        <span class="level">{{doctorInfo.lczc}}</span>
                        <a @click="watchDoctorDetail" class="link-btn01 f-fr">医生主页</a>
                    </div>
                    <div class="info-bottom">
                        <span>消化科</span>
                    </div>
                </div>
            </div>
            <div class="m-address01">
                <span class="f-fl">{{hospitalInfo.hosName}}</span>
                <a @click="watchAppointRules" class="link-btn01 f-fl">预约规则</a>
            </div>
            <div class="m-appointment01">
                <!-- <div class="appointment-top-box01">
                    <img src="../../assets/images/icon-gh.png" class="appointment-icon01">
                    <span class="f-fl">内分泌</span>
                    <a href="javascript:;" class="link-btn01 f-fl">
                        <img src="../../assets/images/icon-sx2.png" class="btn-icon01">刷新
                    </a>
                </div> -->
                <div class="m-site-up">
                    <div class="m-site-cion active"></div>
                </div>
                <ul class="appointment-con01" style="display: block;">
                    <li class="appointment-box01" v-for="item in visitRecordList" :key="item.id">
                        <div class="appoint-time-con01">
                            <span class="appoint-time01">{{item.dateTime}}</span>
                            <span class="appoint-time02">{{item.time}}</span>
                        </div>
                        <div class="appoint-type-con01">
                            <span class="appoint-time01">{{item.type}}</span>
                            <span class="appoint-time02">诊查费<font class="red">{{item.price}}</font>元({{item.payType}})</span>
                        </div>
                        <a @click="toRecord(item.recordStatus)" class="btn05" :class="item.recordStatus === 1 ? '' : 'disable'" v-html="item.recordStatus === 1 ? '预约' : '约满'"></a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
    data() {
        return {
            visitRecordList: [{
                id: 1,
                dateTime: '2021-03-24',
                time: '星期三上午',
                type: '普通门诊',
                price: 50,
                payType: '到院付',
                recordStatus: 1
            }, {
                id: 2,
                dateTime: '2021-03-24',
                time: '星期三下午',
                type: '特需门诊',
                price: 50,
                payType: '到院付',
                recordStatus: 2
            }]
        }
    },
    computed: {
        ...mapState({
            doctorInfo: state => state.layout.doctorInfo,
            scheduleData: state => state.layout.scheduleData,
            hospitalInfo: state => state.layout.hospitalInfo,
        })
    },
    mounted() {
        // 获取医生的排班信息
        Promise.all([this.getDoctorInfo(), this.getHospitalInfo()]).then(() => {
            this.$store.dispatch('getSchedule', {
                hospitalId: this.doctorInfo.hospitalId,
                departmentId: this.doctorInfo.hosDeptId,
                doctorSn: this.doctorInfo.doctorSn,
                pageIndex: 1
            }).then(() => {
                console.log('scheduleData,', this.scheduleData)
            })
        })
    },
    methods: {
        async getDoctorInfo() {
            // 获取该医生相关的信息
            let data = await this.$store.dispatch('queryDoctorInfo', {
                doctorSn: this.$route.query['doctorSn'],
            })

            return data
        },
        async getHospitalInfo() {
            // 获取医院的信息
            let data = await this.$store.dispatch('getHospitalInfo', {
                hospitalId: this.$route.query['hospitalId'] 
            })

            return data
        },
        watchDoctorDetail() { 
            this.$router.push('/doctorDetail')
        }, 
        watchAppointRules() {
            this.$router.push('/appointRules')
        },
        toRecord(status) {
            if(status === 2) return;
            this.$router.push('/record') 
        }
    }
}
</script>
<style lang="scss" scoped>
$mainColor: #00C2C3;
.m-header01 {
    text-align: left;
}
.btn05 {
    background: $mainColor;

    &.disable {
        background: #ccc;
    }
}

.m-site-cion {
    background: none;
}

.link-btn01 {
    border-color: $mainColor;
    color: $mainColor;
}

.detail-lab02:before, .detail-lab03:before {
    background: $mainColor;
}

.doctor {
    display: flex;
    height: 70px;
    background: #fff;
    padding: 12px;
    box-sizing: border-box;
    margin-bottom: 9px;

    .avadar {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border-radius: 1px solid $mainColor;
        margin-right: 10px;

        img {
            width: 100%;
        }
    }
    .info-up {
        display: flex;
        justify-content: flex-start;
        .name {
            font-size: 16px;
            font-weight: 700;
            font-family: PingFangSC-bold;
        }

        .level {
            margin: 0 7px 0 10px;
        }
    }
    .info-bottom {
        text-align: left;
        color: #ccc;
    }
}
</style>